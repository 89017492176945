@import "../../../styles/variables.scss";

// ! Roadmap
.roadmap {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  @include media("<=889px") {
    display: none;
  }
  .roadmap-box {
    width: 95%;
    background-color: #1c1e24;
    z-index: 2;
    display: flex;
    flex-direction: row-reverse;
    clip-path: $pixelBorderRadius;
    .roadmap-box-leftside {
      width: 35%;

      img {
        width: max-content;
        height: 100%;
        object-fit: cover;
      }
    }
    .roadmap-box-rightside {
      width: 65%;
      display: flex;
      flex-direction: column;
      padding: 2rem 0 0 0;
      gap: 2rem;
      border-radius: 0 32px 32px 0;
      .roadmap-title {
        font-family: $font-superStar;
        font-size: 124px;
        max-width: 28rem;
        line-height: 100px;
        color: white;
        padding: 0 2rem;
        text-shadow: 0px 15.2108px 0px #000000;
      }
      .roadmap-headers {
        max-width: 55rem;
        display: flex;
        color: white;
        font-size: 24px;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
        // padding-left: 3.5rem;
        padding-right: 0.9rem;
        font-weight: 400;
        .headers-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 7rem;
          position: relative;
          .website-progress-bar-wrapper {
            img {
              overflow: hidden;
            }
          }
          .progress-box {
            width: 90%;
            border-radius: 10px 6px 6px 10px;
            background-color: #7f1d1d;
            height: 30px;
            position: absolute;
            top: 75%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
          .progress-box-side {
            width: 75px;
            border-radius: 5px 0 0 5px;
            background-color: #7f1d1d;
            height: 25px;
            position: absolute;
            top: 75%;
            left: 37.5%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }
      }
      .roadmap-marquee {
        font-family: $font-superStar;
        color: #fff;
        width: 100%;
        span {
          margin: 0 1rem;
          font-size: 64px;
          word-spacing: -7.5px;
        }
      }
    }
  }
}
