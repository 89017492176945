@import "../../styles/variables.scss";

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.missions {
  width: 100vw;
  background-image: url("../../assets/media/images/missionsBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 2.5rem;
  padding: 3.5rem;
  background-color: hsla(7, 26%, 10%, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include media("<=889px") {
    padding: 1rem;
    background-image: none;
  }
  .graveyard-wrapper {
    z-index: 100;
    position: fixed;
    right: 0;
    top: 0;
    height: 61.5rem;
    width: 7rem;
    display: flex;
    align-items: center;
    cursor: $cursor-Pointer;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    background: rgb(40, 40, 40);
    background: linear-gradient(
      270deg,
      rgba(40, 40, 40, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    justify-content: center;
    @include media("<=889px") {
      height: 100vh;
    }
    @include media("<=480px") {
      width: 4rem;
    }
    &:hover {
      background: rgb(28, 30, 36);
      background: linear-gradient(
        270deg,
        rgba(28, 30, 36, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    &.scrolled {
      transform: translateX(100%);
      @include media("<=950px") {
        transform: translateX(0);
      }
    }
    svg {
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      path {
        fill: white;
      }
    }
    &:hover {
      svg {
        transform: translateX(30px);
      }
      .graveyard {
        .graveyard-text {
          opacity: 1;
          transform: translateX(-45px);
        }
      }
    }
    .graveyard {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      .graveyard-text {
        opacity: 0;
        transform: translateX(-35px);
        font-family: $font-superStar;
        font-size: 26px;
        position: absolute;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
  }
  .missions-top-side {
    padding: 0 5rem;
    width: 100vw;
    @include media("<=889px") {
      margin: 4rem 0 1rem 0;
      padding: 0 1rem;
    }
    .yoursbears-container {
      z-index: 5;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-superStar;
      position: fixed;
      bottom: 0;
      right: 0;
      transform: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      .yoursbears-container-leftside {
        font-size: 28px;
        color: white;
      }
      .yourbears-container-rightside {
        .bears-count-container {
          // We will stylize it when we got the bears data.
        }
        .graveyard-button-container {
          border-radius: 12.5px;
          border: 2px solid transparent;
          position: relative;
          width: 12.5rem;
          display: flex;
          justify-content: center;
          cursor: $cursor-Pointer;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.hide {
            transform: translateY(110%);
          }
          .tomb {
            position: absolute;
            z-index: 1;
            width: 45px;
            opacity: 0;
            transition: 0.3s cubic-bezier(0.87, -0.25, 0.48, 1.68);
          }
          .tomb-1 {
            top: 0.5rem;
            left: 0;
            transition-delay: 0.25s;
          }
          .tomb-2 {
            right: 0rem;
            top: 1rem;
            transition-delay: 0.5s;
          }
          .tomb-3 {
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
            transition-delay: 0.75s;
          }
          .dirt {
            position: absolute;
            z-index: 2;
          }
          &:hover {
            .tomb-1 {
              top: -1rem;
              opacity: 1;
            }
            .tomb-2 {
              top: -0.75rem;
              opacity: 1;
            }
            .tomb-3 {
              top: -0.75rem;
              opacity: 1;
            }
            .graveyard-btn {
              transform: translateY(-30px);
              transition-delay: 0.65s;
            }
          }
          .graveyard-btn {
            font-family: $font-superStar;
            color: white;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 28px;
            gap: 0.5rem;
            z-index: 3;
            top: -1rem;
            transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }
      }
    }
  }

  .missions-title {
    color: white;
    display: flex;
    justify-content: center;
    font-family: $font-superStar;
    font-size: 74px;
    text-shadow: 0px 7.5px 0px #000000;
    @include media("<=889px") {
      line-height: normal;
      text-align: center;
      font-size: 42px;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }

  .whitelist-quests-cards-wrapper {
    gap: 2rem;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    @include media("<=889px") {
      flex-direction: column;
      gap: 3.25rem;
    }
    .whitelist-quests-card {
      overflow: hidden;
      position: relative;
      background-color: #1c1e24;
      width: 25rem;
      height: 35rem;
      border-radius: 32px;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      z-index: 2;
      @include media("<=480px") {
        border-radius: 0;
      }
      transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
          rgb(51, 51, 51) 0px 0px 0px 4px;
      }
      .leftcard-gradient {
        top: 6rem;
        left: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background: rgb(28, 30, 36);
        background: linear-gradient(
          180deg,
          rgba(28, 30, 36, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .middlecard-gradient {
        top: 5.75rem;
        left: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background: rgb(28, 30, 36);
        background: linear-gradient(
          180deg,
          rgba(28, 30, 36, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .rightcard-gradient {
        top: 6.9rem;
        left: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 5rem;
        background: rgb(28, 30, 36);
        background: linear-gradient(
          180deg,
          rgba(28, 30, 36, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      .leftcard-background {
        position: absolute;
        width: 100%;
        bottom: 3.75rem;

        pointer-events: none;
      }
      .rightcard-background {
        filter: brightness(1.7);
        position: absolute;
        bottom: 3.1rem;
        width: 100%;
        pointer-events: none;
      }
      &.card-middle {
        width: 30rem;
        height: 40rem;
        -webkit-box-shadow: 0px 0px 54px 8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 54px 8px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 54px 8px rgba(0, 0, 0, 0.75);
        z-index: 3;
        transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
            rgb(51, 51, 51) 0px 0px 0px 3px;
        }
        .middlecard-background {
          width: 100%;
          position: absolute;
          bottom: 4.1rem;
          pointer-events: none;
        }
      }
      &.coming-soon {
        filter: saturate(0);
      }

      .whitelist-quests-card-texts-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #dedede;
        position: relative;
        z-index: 10;
        .whitelist-quests-card-title {
          font-family: $font-superStar;
          font-size: 42px;
          text-shadow: 0px 5px 0px #000000;
        }
        .whitelist-quests-card-description {
          font-size: 24px;
          text-align: center;
          line-height: normal;
          color: #999999;
          @include media("<=889px") {
            padding: 0 2rem;
            font-size: 22px;
          }
        }
      }
      .whitelist-quests-card-button-wrapper {
        width: 100%;
        height: 4rem;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        .card-bottom-background {
          position: absolute;
          scale: 2;
          bottom: 0.7rem;
          left: -0.75rem;
          filter: contrast(1.5) brightness(0.3);
          pointer-events: none;
          &.middle {
            scale: 1.6;
            bottom: 1.1rem;
            left: -1rem;
          }
        }
        .whitelist-quests-card-button {
          position: absolute;
          top: -1rem;
          cursor: $cursor-Pointer;
          display: flex;
          align-items: center;
          padding: 0.5rem 1rem;
          background-color: #7f1d1d;
          box-shadow: 0px 3.49091px 0px #4f0e0e,
            0px 6.54545px 0px rgba(0, 0, 0, 0.15);
          border-radius: 15.7091px;
          z-index: 5;
          font-family: $font-superStar;
          color: #ffffff;
          border-radius: 7.5px;
          font-size: 24px;
          gap: 0.5rem;
          border-radius: 7.5px;
          transition: 0.1s ease-in;
          &:hover {
            transform: translateY(2.5px);
            box-shadow: 0px 0px 0px #4f0e0e, 0px 0px 0px rgba(0, 0, 0, 0.15);
          }
          &:active {
            transform: translateY(5px);
          }
        }
      }
    }
  }
}
