@import "../../styles/variables.scss";

.intro {
  background-image: url("../../assets/media/Intro/Hall/hallBackground.webp");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .intro-clouds-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #b8b9d3;
    z-index: 0;
  }

  .parallax-container {
    width: 100vw;
    height: 100vh;
    position: relative;
    .cloud-topleft,
    .cloud-topleft-two,
    .cloud-bottomleft,
    .cloud-bottommiddle,
    .cloud-bottommiddle-two,
    .cloud-bottomright,
    .cloud-topright {
      position: absolute;

      pointer-events: all;
    }

    .cloud-topleft {
      top: 0;
      left: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 1;
    }
    .cloud-topleft-two {
      top: 0;
      left: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 1;
    }
    .cloud-bottomleft {
      bottom: 0;
      left: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 0;
    }

    .cloud-bottommiddle {
      bottom: 0;
      left: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 1;
    }

    .cloud-bottommiddle-two {
      top: 0;
      right: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 0;
    }

    .cloud-bottomright {
      bottom: 0;
      right: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 1;
    }
    .cloud-topright {
      top: 0;
      right: 0;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      z-index: 1;
    }
  }

  .intro-buttons-wrapper {
    height: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: calc(50% - -8.5rem);
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
    @include media("<=889px") {
      height: 6rem;
    }
    @include media("<=380px") {
      top: calc(50% - -7rem);
    }
    .enter-button {
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../../assets/media/icons/enterButton.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 32px;
      width: 11.5rem;
      font-family: $font-superStar;
      color: black;
      -webkit-text-fill-color: white;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
      transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        cursor: $cursor-Pointer;
        scale: 1.02;
      }
      &:active {
        transform: translateY(2px);
      }
    }

    .sound-checkbox {
      color: white;
      text-shadow: 0px 1.5px 0px #000000;
      font-family: $font-superStar;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      .checkbox-not-hover {
        position: absolute;
        left: 3.125rem;
      }
      img {
        width: 17.5px;
      }

      &:hover {
        cursor: $cursor-Pointer;
        .checkbox-not-hover {
          opacity: 0;
        }
      }
    }
  }
}
// Intro hall

.hall-section-wrapper {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  position: absolute;

  @include media("<=889px") {
    height: auto;
  }
  .hallbears-frame-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60rem;
    height: 60rem;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    align-items: center;
    background-color: transparent;
    .frame-wrapper {
      .sagebear-frame {
        z-index: 10;
        position: absolute;
        text-align: center;
        width: 12.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 3.85rem;
        transform: translateY(-50px);
        opacity: 0;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        &.show {
          opacity: 1;
          transform: translateY(-60px);
        }
        img {
          position: absolute;
        }
        .sagebear-frame-text {
          position: relative;
          font-size: 24px;
          top: 2rem;
          font-family: $font-superStar;
        }
      }
    }
  }
}

// BarBearians Logo

.logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: absolute;
  z-index: 13;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 350px;
    @include media("<=889px") {
      scale: 1.2;
    }
  }
  p {
    font-size: 38px;
    font-weight: 1000;
    font-family: "SuperStar", Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    text-align: center;
    line-height: normal;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: #000000;
    @include media("<=889px") {
      line-height: 22px;
      font-size: 24px;
      margin-top: 2rem;
      text-align: center;
    }
    @include media("<=480px") {
      margin-top: 1rem;
    }
  }
}
