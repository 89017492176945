@import "../../../styles/variables.scss";

.thebears {
  background-color: hsla(0, 76%, 6%, 1);
  background-image: radial-gradient(
      at 100% 2%,
      hsla(240, 0%, 0%, 0.73) 0px,
      transparent 50%
    ),
    radial-gradient(at 52% 46%, hsla(340, 0%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 36% 83%, hsla(340, 0%, 2%, 1) 0px, transparent 50%);
  width: 100vw;
  display: flex;
  flex-direction: column;
  @include media("<=889px") {
    height: 45rem;
    margin-top: 5rem;
  }
  @include media("<=480px") {
    height: auto;
    margin-top: 0;
  }
  .title-wrapper {
    height: 20rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-superStar;
    font-size: 124px;
    color: #ffffff;
    text-shadow: 0px 15.2108px 0px #000000;
    @include media("<=950px") {
      height: 10rem;
    }
    @include media("<=889px") {
      margin-bottom: 0;
    }
    @include media("<=480px") {
      font-size: 64px;
      height: 10rem;
      text-shadow: 0px 7.5px 0px #000000;
      align-items: flex-end;
    }
  }
  .bottom-section-wrapper {
    height: 70vh;
    color: white;
    display: flex;
    position: relative;

    @include media("<=480px") {
      height: auto;
    }
    .left-side {
      width: 50vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include media("<=950px") {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: flex-start;
      }
      .bear-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 2rem;
        width: 35rem;
        height: 30rem;
        @include media("<=950px") {
          height: auto;
          width: auto;
          padding: 0 3rem;
        }
        @include media("<=480px") {
          width: 100vw;
          padding: 0 2rem;
        }
        .bear-header-wrapper {
          display: flex;
          flex-direction: column;
          .bear-name {
            font-size: 48px;
            font-family: $font-superStar;
            color: #7f1d1d;
            position: relative;
            top: 0.75rem;
            @include media("<=889px") {
              display: none;
            }
          }
          .bearians-info {
            max-width: 30rem;
            font-size: 34px;
            line-height: normal;
            color: #dedede;
            @include media("<=950px") {
              max-width: none;
            }
            @include media("<=480px") {
              font-size: 28px;
              max-width: 30rem;
            }
          }
        }
        .mission-box-wrapper {
          clip-path: $pixelBorderRadius;
          width: 26.5rem;
          display: flex;
          flex-direction: column;
          border-radius: 24px;
          border: 1.5px solid rgba(197, 197, 197, 0.4);
          @include media("<=480px") {
            display: none;
          }
          .mission-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            .mission-left-side {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              .mission-label-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: $font-superStar;
                line-height: normal;
                .mission-label {
                  position: relative;
                  top: 3px;
                  font-size: 16px;
                  color: #969392;
                }
                .mission {
                  font-size: 24px;
                }
              }
            }
            .mission-right-side {
              .coming-soon {
                font-family: $font-superStar;
                color: #969392;
              }
            }
          }
          .middle-mission {
            border-top: 1.5px solid rgba(197, 197, 197, 0.4);
            border-bottom: 1.5px solid rgba(197, 197, 197, 0.4);
          }
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      width: 50vw;
      @include media("<=889px") {
        display: none;
      }

      .bear-card-wrapper {
        position: absolute;
        overflow: hidden;
        box-shadow: 0px 1px 2.2px rgba(0, 0, 0, 0.02),
          0px 2.3px 5.3px rgba(0, 0, 0, 0.028),
          0px 4.4px 10px rgba(0, 0, 0, 0.035),
          0px 7.8px 17.9px rgba(0, 0, 0, 0.042),
          0px 14.6px 33.4px rgba(0, 0, 0, 0.05),
          0px 35px 80px rgba(0, 0, 0, 0.07);
        .hostagebear-chain1 {
          position: absolute;
          bottom: 2rem;
        }
        .hostagebear-chain2 {
          position: absolute;
          bottom: 0rem;
          scale: 1.5;
          rotate: -25deg;
        }
      }
      .ellipse-wrapper {
        position: relative;
        left: 3rem;
        path {
          fill: #7f1d1d;
        }
        .ellipse-back {
          position: absolute;
        }
        .noobie-bear-button-wrapper {
          cursor: $cursor-Pointer;
          height: 3rem;
          position: absolute;
          top: 1.75rem;
          right: -0.5rem;
          display: flex;
          align-items: center;
          background-color: transparent;
          gap: 1rem;
          &:hover .bearbutton-right-side {
            color: #ffffff;
          }
          &:hover .bearbutton-left-side {
            background-color: #583636;
          }
          .bearbutton-left-side {
            border-radius: 50%;
            background-color: #512e2e;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;

            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            img {
              width: 30px;
            }
          }
          .bearbutton-right-side {
            font-family: $font-superStar;
            color: #7d7a79;
            font-size: 24px;
            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            animation: breathe infinite 3s;
            &.active {
              color: #7f1d1d;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              animation: none;
            }
            @keyframes breathe {
              0% {
                color: #7d7a79;
              }
              50% {
                color: #ffffff;
              }
              100% {
                color: #7d7a79;
              }
            }
          }
        }
        .battleready-bear-button-wrapper {
          cursor: $cursor-Pointer;
          height: 3rem;
          position: absolute;
          top: 10rem;
          right: -7.75rem;
          display: flex;
          align-items: center;
          background-color: transparent;
          gap: 1rem;
          &:hover .bearbutton-right-side {
            color: #ffffff;
          }
          &:hover .bearbutton-left-side {
            background-color: #f7b075;
          }
          .bearbutton-left-side {
            border-radius: 50%;
            background-color: #ffa255;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;

            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            img {
              width: 30px;
            }
          }
          .bearbutton-right-side {
            font-family: $font-superStar;
            color: #7d7a79;
            font-size: 24px;
            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            animation: breathe infinite 3s;
            &.active {
              color: #7f1d1d;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              animation: none;
            }
            @keyframes breathe {
              0% {
                color: #7d7a79;
              }
              50% {
                color: #ffffff;
              }
              100% {
                color: #7d7a79;
              }
            }
          }
        }
        .hostage-bear-button-wrapper {
          cursor: $cursor-Pointer;
          height: 3rem;
          position: absolute;
          bottom: 13rem;
          right: -9.5rem;
          display: flex;
          align-items: center;
          background-color: transparent;
          gap: 1rem;
          &:hover .bearbutton-right-side {
            color: #ffffff;
          }
          &:hover .bearbutton-left-side {
            background-color: #583636;
          }
          .bearbutton-left-side {
            filter: saturate(0%);
            border-radius: 50%;
            background-color: #512e2e;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            overflow: hidden;

            img {
              width: 30px;
            }
            .chain-1 {
              position: absolute;
              scale: 3;
              bottom: 15px;
            }
            .chain-2 {
              position: absolute;
              rotate: 40deg;
              scale: 3;
            }
          }
          .bearbutton-right-side {
            font-family: $font-superStar;
            color: #7d7a79;
            font-size: 24px;
            animation: breathe infinite 3s;
            &.active {
              color: #7f1d1d;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              animation: none;
            }
            @keyframes breathe {
              0% {
                color: #7d7a79;
              }
              50% {
                color: #ffffff;
              }
              100% {
                color: #7d7a79;
              }
            }
          }
        }
        .lost-bear-button-wrapper {
          cursor: $cursor-Pointer;
          height: 3rem;
          position: absolute;
          bottom: 3rem;
          right: -1rem;
          display: flex;
          align-items: center;
          background-color: transparent;
          gap: 1rem;
          &:hover .bearbutton-right-side {
            color: #ffffff;
          }
          &:hover .bearbutton-left-side {
            background-color: #3c3c3c;
          }
          .bearbutton-left-side {
            border-radius: 50%;
            background-color: #212020;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;

            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            img {
              width: 30px;
            }
          }
          .bearbutton-right-side {
            font-family: $font-superStar;
            color: #7d7a79;
            font-size: 24px;
            transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            animation: breathe infinite 3s;
            &.active {
              color: #7f1d1d;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              animation: none;
            }
            @keyframes breathe {
              0% {
                color: #7d7a79;
              }
              50% {
                color: #ffffff;
              }
              100% {
                color: #7d7a79;
              }
            }
          }
        }
      }
    }
  }
  .mission-infos-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: $font-superStar;
    color: white;
    font-size: 42px;
    align-items: center;
    @include media("<=1550px") {
      padding: 6rem 2rem;
    }
    @include media("<=950px") {
      display: none;
    }
    .mission-info-title {
      z-index: 1;
    }

    .missions-description-card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      position: relative;

      .crack {
        position: absolute;
        z-index: 0;
        &.crack-1 {
          bottom: 0;
          left: -5rem;
          rotate: -140deg;
          width: 100px;
          filter: invert(1);
          clip-path: inset(100% 0 0 0);
        }
        &.crack-2 {
          left: 20%;
          clip-path: inset(100% 0 0 0);
          rotate: -140deg;
          width: 100px;
          filter: invert(1);
          transform: translate(-300px, 250px);
          top: 0;
          left: -3rem;
        }
      }
      .description-card {
        background-size: cover;
        background-position: center;
        font-size: 20px;
        font-family: $font-Pixellari;
        width: 25rem;
        display: flex;
        justify-content: center;
        text-align: center;
        line-height: 110%;
        padding: 1rem;
        border-radius: 15px;
        height: 15rem;
        position: relative;
        overflow: hidden;
        &.train {
          video {
            position: absolute;
            z-index: -1;
          }
        }
        &.battle {
          background-image: none;
        }
        &.mint {
          video {
            position: absolute;
            z-index: -1;
          }
        }
        .description-card-video {
          position: absolute;
          top: -75px;
          left: 0;
          width: 100%;
        }
        .description-card-title {
          z-index: 2;
          text-align: left;
        }
        .description-gradient {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background: rgb(69, 69, 69);
          background: linear-gradient(
            180deg,
            #1c1e23 20%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
    }
  }
}
