@import "../../styles/variables.scss";

.cart-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: $cursor-Pointer;
  background-color: rgba(255, 255, 255, 0.122);
  border-radius: 5px;
  position: fixed;
  right: 3rem;
  top: 7.5rem;
  z-index: 10;
  &:hover {
    .cart-icon {
      path {
        fill: white;
      }
    }
  }
  .cart-icon {
    width: 30px;
    height: 30px;

    path {
      fill: #d9d9d98c;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
  .cart-count {
    position: absolute;
    top: -0.5rem;
    right: -1rem;
    border: 1px solid rgb(49, 49, 49);
    font-size: 20px;
    color: #151515;
    background-color: #ddd;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
  }
}

.marketplace {
  // overscroll-behavior: none;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  padding: 6rem 0 6rem 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  .marketplace-title {
    width: 100%;
    margin-top: -7rem;
    text-align: center;
    border-bottom: 2px solid #303030;
    padding-bottom: 2rem;
    user-select: none;
    position: relative;
    h3 {
      font-family: $font-superStar;
      text-shadow: 0px 7.5px 0px #000000;
      font-size: 156px;
      color: white;
      font-weight: 100;
      position: relative;
      top: 5rem;

      @include media("<=950px") {
        font-size: 102px;
        padding-bottom: 1rem;
      }
      @include media("<=620px") {
        font-size: 72px;
        padding-bottom: 2rem;
      }
    }
    .howitworks-marketplace-btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #7f1d1d;
      font-family: $font-superStar;
      font-size: 24px;
      gap: 0.3rem;
      right: 7rem;
      cursor: $cursor-Pointer;
      &:hover {
        text-decoration: underline;
      }
      svg {
        path {
          fill: #7f1d1d;
        }
      }
      @include media("<=950px") {
        font-size: 18px;
        position: absolute;
        right: 1rem;
        transform: translateY(-335%);
      }
      @include media("<=480px") {
        transform: translateY(-375%);
      }

      .howitworks-marketplace-text {
        position: relative;
        top: 0.1rem;
        @include media("<=740px") {
          display: none;
        }
      }
    }
  }
  .marketplace-inner {
    display: flex;
    .marketplace-left {
      display: flex;
      position: sticky;
      top: 1rem;
      .filters-box {
        width: 30rem;
        @include media("<=1550px") {
          width: 17.5rem;
        }

        .filters-box-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 0;
          .filters {
            color: white;
            font-family: $font-superStar;
            font-size: 34px;
            font-weight: normal;
          }
          .clear-button {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: $cursor-Pointer;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            font-size: 20px;
            font-family: $font-superStar;
            color: white;
            transition: 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
            &:hover {
              background-color: rgba(255, 255, 255, 0.25);
            }
            .repeat-icon {
              transition: 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
            }
            &:hover .repeat-icon {
              rotate: 180deg;
            }
          }
        }

        .bears-search {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          padding: 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          .bears-search-input {
            font-family: $font-superStar;
            outline: none;
            border: none;
            background-color: transparent;
            color: white;
            font-size: 18px;
            letter-spacing: 1.2px;
            width: 90%;
            &::placeholder {
              color: rgba(222, 222, 222, 0.5);
            }
          }
        }

        .background-filter {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0.75rem 1rem 0.75rem 1rem;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          height: 3.75rem;
          overflow: hidden;
          gap: 1rem;
          &:hover {
            background-color: #262626;
          }
          &.selected {
            height: max-content;
          }
          .background-filter-top {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .background-filter-left {
              display: flex;
              gap: 0.75rem;
              align-items: center;
              .background {
                color: white;
                font-family: $font-superStar;
                font-size: 22px;
                font-weight: normal;
                color: #dedede;
              }
            }
            .background-filter-right {
              display: flex;
              align-items: center;
              .arrow-icon {
                width: 25px;
                height: 25px;
                transform: rotate(-90deg);
                transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                &.open {
                  transform: rotate(90deg);
                }
                path {
                  fill: #dedede;
                }
              }
            }
          }

          .select-filters-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-size: 18px;
            justify-content: space-between;
            align-items: center;
            color: #ddd;
            cursor: $cursor-Default;
            gap: 0.5rem 0rem;
            @include media("<=1550px") {
              justify-content: center;
              align-items: center;
              flex-wrap: nowrap;
              flex-direction: column;
            }
            .background-filter-item {
              width: 33.33%;
              @include media("<=1550px") {
                width: auto;
              }
              label {
                cursor: $cursor-Pointer;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .checkbox-img {
                  width: 15.5px;
                }
              }
            }
          }
        }

        .weaponpack-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .weaponpack-filter-left .weaponpack {
            color: #fff;
          }
          .weaponpack-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
              rotate: 225deg;
            }
            .weaponpack {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .weaponpack-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .fur-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .fur-filter-left .fur {
            color: #fff;
          }
          .fur-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .fur {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .fur-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .armor-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .armor-filter-left .armor {
            color: #fff;
          }
          .armor-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .armor {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .armor-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .expression-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .expression-filter-left .expression {
            color: #fff;
          }
          .expression-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .expression {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .expression-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .facestwo-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .facestwo-filter-left .facestwo {
            color: #fff;
          }
          .facestwo-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .facestwo {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .facestwo-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .accessory-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .accessory-filter-left .accessory {
            color: #fff;
          }
          .accessory-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .accessory {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .accessory-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .headpiece-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .headpiece-filter-left .headpiece {
            color: #fff;
          }
          .headpiece-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .headpiece {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .headpiece-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .weaponfront-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .weaponfront-filter-left .weaponfront {
            color: #fff;
          }
          .weaponfront-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .weaponfront {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .weaponfront-filter-right {
            display: flex;
            align-items: center;
          }
        }

        .specialeffect-filter {
          cursor: $cursor-Pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.75rem 1rem 0.75rem 0;
          border-bottom: 1px solid rgba(167, 166, 166, 0.25);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &.selected {
            background-color: rgba(255, 255, 255, 0.1);
          }
          &:hover .specialeffect-filter-left .specialeffect {
            color: #fff;
          }
          .specialeffect-filter-left {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            img {
              width: 25px;
            }
            .specialeffect {
              color: white;
              font-family: $font-superStar;
              font-size: 22px;
              font-weight: normal;
              color: #dedede;
            }
          }
          .specialeffect-filter-right {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .marketplace-right {
      width: 100%;
      height: 100vh;
      padding: 0 0 0 2rem;
      .marketplace-right-top-wrapper {
        display: flex;
        width: 100%;
        padding-right: 2rem;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(167, 166, 166, 0.25);
        .marketplace-right-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // padding: 0.5rem 0;
          color: white;
          font-family: $font-superStar;
          font-size: 34px;
          font-weight: normal;
          // top: 1rem
          position: relative;
        }
        .current-visible-items {
          padding: 0.5rem 0 0.15rem 0;
          color: white;
          font-size: 14px;
          font-weight: 1000;
          letter-spacing: 0.75px;
          span {
            color: #9e9a9a;
          }
        }

        .marketplace-right-wrapper {
          display: flex;
          align-items: center;
          margin-right: 5rem;
          .daily-rewards-wrapper {
            position: relative;
            top: 0.25rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: white;
            font-size: 20px;
            padding: 1rem 0;
            .daily-rewards-right {
              .daily-rewards-title {
                color: #d9d9d98c;
              }
            }
          }
        }
      }
      .marketplace-skeleton-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1.75rem;
        margin-top: 1.5rem;
        padding-right: 1.5rem;
        width: 100%;
        overflow-y: auto;
        @include media("<=1550px") {
          gap: 1rem 0.5rem;
        }
      }

      .bear-market {
        display: flex;
        flex-wrap: wrap;
        gap: 1.75rem;
        margin-top: 1.5rem;
        width: 100%;
        overflow-y: auto;
        @include media("<=1550px") {
          gap: 1rem 0.5rem;
        }

        .marketplace-card-wrapper {
          width: 15rem;
          height: 25rem;
          background-color: #262626;
          border-radius: 8px;
          box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.02),
            0px 0px 5.3px rgba(0, 0, 0, 0.028),
            0px 0px 10px rgba(0, 0, 0, 0.035),
            0px 0px 17.9px rgba(0, 0, 0, 0.042),
            0px 0px 33.4px rgba(0, 0, 0, 0.05), 0px 0px 80px rgba(0, 0, 0, 0.07);
          &.selected {
            -webkit-box-shadow: 0px 0px 19px 0px rgba(255, 255, 255, 0.4);
            -moz-box-shadow: 0px 0px 19px 0px rgba(255, 255, 255, 0.4);
            box-shadow: 0px 0px 19px 0px rgba(255, 255, 255, 0.4);
            background-color: #494949;
          }
          .marketplace-card-image-wrapper {
            width: 100%;
            height: 12.5rem;
            border-radius: 8px 8px 0 0;
            position: relative;
            img {
              border-radius: 8px 8px 0 0;
              width: 100%;
              height: 12.5rem;
              object-fit: cover;
            }
            .metadata-info-btn {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0.25rem;
              right: 0.25rem;
              border-radius: 12.5px;
              cursor: $cursor-Pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.168);
                span {
                  opacity: 1;
                }
              }

              &.active {
                background-color: #151515;
              }

              span {
                text-align: center;
                border-radius: 12.5px;
                background-color: rgba(0, 0, 0, 0.576);
                position: absolute;
                font-family: $font-superStar;
                left: -7.5rem;
                width: 112.5px;
                padding: 0.1rem 0.25rem;
                color: white;
                pointer-events: none;
                user-select: none;
                opacity: 0;
              }
            }
          }
          .marketplace-card-bottom {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            height: 12.5rem;
            border-radius: 0 0 8px 8px;
            justify-content: space-between;
            .bear-information-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 1rem;
              height: 6rem;
              .bear-information-top {
                display: flex;
                gap: 2rem;
                align-items: center;

                &.not-metadata {
                  justify-content: space-between;
                }
                .bear-type {
                  display: flex;
                  flex-direction: column;
                  line-height: 18px;
                  .not-metadata {
                    line-height: 32px;
                  }
                  .type {
                    color: #9e9a9a;
                    font-family: $font-superStar;
                    font-size: 18px;
                    &.bear-id {
                      font-size: 24px;
                    }
                  }
                  .bear-type-info {
                    color: #ffffff;
                    font-family: $font-superStar;
                    font-size: 18px;
                    &.not-metadata {
                      font-size: 36px;
                    }
                  }
                }
                .bear-id {
                  display: flex;
                  flex-direction: column;
                  line-height: 18px;
                  position: relative;
                  &.not-metadata {
                    line-height: 32px;
                  }
                  .id {
                    color: #9e9a9a;
                    font-family: $font-superStar;
                    font-size: 18px;
                    &.not-metadata {
                      line-height: 24px;
                    }
                    &.back-name {
                      position: relative;
                    }
                  }
                  .bear-id-number {
                    color: #ffffff;
                    font-family: $font-superStar;
                    font-size: 18px;
                    &.not-metadata {
                      font-size: 36px;
                    }
                    &.fur-text:hover {
                      .fur-tooltip-text {
                        visibility: visible;
                      }
                    }
                    &.fur-text {
                      .fur-tooltip-text {
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        font-size: 20px;
                        padding: 0.25rem;
                        visibility: hidden;
                        background-color: black;
                        border-radius: 10px;
                        user-select: none;
                        pointer-events: none;
                        border: 1px solid white;
                      }
                    }
                    &.back-coat {
                      max-width: 5rem;

                      &:hover {
                        .tooltip-text {
                          visibility: visible;
                          line-height: 26px;
                          font-size: 24px;
                        }
                      }
                      .tooltip-text {
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        font-size: 36px;
                        padding: 0.25rem;
                        visibility: hidden;
                        background-color: black;
                        border-radius: 10px;
                        user-select: none;
                        pointer-events: none;
                        border: 1px solid white;
                      }
                    }
                  }
                }
              }
              .bear-information-bottom {
                display: flex;

                justify-content: space-between;
                align-items: center;
                .bear-rarity {
                  display: flex;
                  flex-direction: column;
                  line-height: 18px;
                  .rarity {
                    color: #9e9a9a;
                    font-family: $font-superStar;
                    font-size: 18px;
                  }
                  .bear-rarity-score {
                    color: #ffffff;
                    font-family: $font-superStar;
                    font-size: 26px;
                  }
                }
                .bear-rank {
                  display: flex;
                  flex-direction: column;
                  line-height: 18px;
                  .rank {
                    color: #9e9a9a;
                    font-family: $font-superStar;
                    font-size: 18px;
                  }
                  .bear-rank-number {
                    color: #ffffff;
                    font-family: $font-superStar;
                    font-size: 26px;
                  }
                }
              }
            }

            .buy-button {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: $cursor-Pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgb(99, 99, 99);
              border-radius: 4px;
              padding: 6px 16px;
              font-family: $font-superStar;
              color: white;
              gap: 1rem;
              font-size: 26px;
              transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
              &:hover {
                background-color: #959595;
              }
              .price-wrapper {
                display: flex;
                gap: 0.25rem;
                align-items: center;
                justify-content: center;
                img {
                  position: relative;
                  top: -0.05rem;
                }
                .remove-text {
                  color: #fb7185;
                }
              }
            }
          }
        }
      }
      .loadmore-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        .load-more-btn {
          cursor: $cursor-Pointer;
          width: 250px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $font-superStar;
          font-size: 24px;
          background-color: #151515;
          border-radius: 5px;
          border: 2px solid #9e9a9a;
          color: #ddd;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:hover {
            background-color: #262626;
          }
          &:active {
            background-color: #808080;
          }
        }
      }
    }
  }
}
