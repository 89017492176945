@import "../../../styles/variables.scss";

// ! Auction Sale
.auctionsale {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  background-color: hsla(0, 76%, 6%, 1);
  background-image: radial-gradient(
      at 94% 97%,
      hsla(323, 0%, 0%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 21% 99%, hsla(240, 0%, 3%, 1) 0px, transparent 50%),
    radial-gradient(at 94% 4%, hsla(240, 0%, 0%, 0.73) 0px, transparent 50%),
    radial-gradient(at 18% 8%, hsla(340, 0%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 85% 1%, hsla(0, 0%, 0%, 1) 0px, transparent 50%);
  width: 100vw;
  padding: 7.5rem 0 0 0;
  @include media("<=950px") {
    gap: 0rem;
  }
  @include media("<=480px") {
    padding: 2rem;
    gap: 0;
  }
  @include media("<=380px") {
    padding: 5rem 2rem 0 2rem;
  }
  .auctionsale-left {
    width: 60%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=950px") {
      width: 100%;
      padding: 0 3.5rem;
    }
    @include media("<=480px") {
      width: 100%;
      padding: 0;
    }
    .auctionsale-left-container {
      height: 35rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @include media("<=889px") {
        height: auto;
        gap: 2rem;
        width: 100%;
      }
      @include media("<=480px") {
        padding: 0;
      }
      .littleheader-wrapper {
        transform: translateY(3.5rem);
        font-size: 32px;
        font-weight: 1000;
        letter-spacing: 1.1px;
        @include media("<=950px") {
          transform: translateY(10px);
        }
      }
      .auction-title {
        font-size: 124px;
        font-family: $font-superStar;
        @include media("<=889px") {
          font-size: 96px;
        }
        @include media("<=480px") {
          font-size: 52px;
          line-height: normal;
        }

        filter: drop-shadow(0 5px black);
        span {
          color: #7f1d1d;
        }
      }
      .auction-directions-wrapper {
        display: flex;
        flex-direction: column;
        @include media("<=480px") {
          line-height: normal;
        }
        p {
          max-width: 50rem;
          font-size: 20px;
        }
      }
    }
  }
  .auctionsale-right {
    width: 40%;

    position: relative;
    img {
      width: 325px;
      position: relative;
      transform: translateX(40px);
    }
    @include media("<=950px") {
      display: none;
    }
    @include media("<=480px") {
      display: none;
    }
  }
}
