@import "../../styles/variables";

.error-page {
  margin-top: 10rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  position: relative;
  @include media("<=480px") {
    flex-direction: column;
  }
  .error-bear-video {
    width: 350px;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    @include media("<=480px") {
      position: relative;
      width: 300px;
      left: -1rem;
    }
  }
  .speech-bubble {
    width: 600px;
    position: absolute;
    transform: translateX(15px);
    top: 0;
    @include media("<=480px") {
      transform: translateX(0);
      left: 0;
    }
  }
  .gotohome-button {
    font-family: $font-superStar;
    text-align: center;
    position: relative;
    font-size: 22px;
    cursor: $cursor-Pointer;
    color: #7f1d1d;
    clip-path: $pixelBorderRadius;
    padding: 0.4rem 1rem;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    background-color: #202020;
    @include media("<=480px") {
      display: none;
    }
    &:hover {
      background-color: #151515;
    }
  }
}
