@import "../../styles/variables.scss";

.hall-scene {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/media/Home/homeLanding.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  .social-links-wrapper {
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem;
    gap: 1rem;

    @include media(">=3000px") {
      max-width: 160rem;
      left: 50%;
      transform: translateX(-50%);
    }
    @include media("<=950px") {
      top: 4rem;
      right: 1.5rem;
    }
    .twitter-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      cursor: $cursor-Pointer;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(8, 8, 12, 0.37);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      background-color: #20202082;
      &:hover {
        background-color: #303030;
      }
      img {
        width: 45px;
      }
    }
    .discord-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      cursor: $cursor-Pointer;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      background-color: #20202082;
      &:hover {
        background-color: #303030;
      }
      img {
        width: 45px;
      }
    }
  }
  .hall-background {
    background-image: url("../../assets/media/Intro/Hall/hallBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
    @include media(">=3000px") {
      background-size: contain;
    }
  }
  .hall-logo-wrapper {
    width: 100%;
    top: 3.5%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
    align-items: center;
    transform-origin: left center;

    .hall-logo {
      filter: drop-shadow(2px 2px 0 black);
      z-index: 125;
      position: relative;
      width: 125px;
      transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

      @include media("<=480px") {
        top: calc(50% - 16rem);
      }
      @include media("<=380px") {
        top: calc(50% - 13rem);
      }
      &:hover {
        cursor: $cursor-Pointer;
        scale: 1.07;
      }
    }
  }
  .mobile-hall-links {
    width: 70%;
    padding: 1rem 0.25rem;
    font-family: $font-superStar;
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 55;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #202020;
    color: white;
    border: 12.5px solid transparent;
    border-radius: 12px;
    border-image: url("../../assets/media/icons/CardBorder.png") 25 stretch;
    transform: translate(-50%, -50%);
    @include media(">=889px") {
      display: none;
    }
    @include media("<889px") {
      width: 50%;
      height: 50%;
      padding: 1rem 0.25rem;
      font-size: 42px;
    }
    @include media("<=480px") {
      width: 70%;
      font-size: 32px;
    }
  }
  .torchlights-wrapper {
    pointer-events: none;

    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;
    @include media(">=3100px") {
      max-width: 150rem;
      left: 50%;
      transform: translateX(-50%);
    }
    @include media(">=2350px") {
      padding: 0 20rem;
    }
    @include media("<=1500px") {
      padding: 0;
    }
    img {
      width: 200px;
      z-index: 2;
      @include media("<=1500px") {
        width: 150px;
      }
      @media screen and (min-height: 1080px) {
        position: relative;
        top: -3.5rem;
      }
      @media screen and (max-height: 700px) {
        display: none;
      }
    }
    .torchlights-two {
      transform: scaleX(-1);
    }
  }
  .bears-container {
    width: 60rem;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    @media screen and (min-height: 889px) {
      top: calc(50% - -40px);
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media screen and (min-height: 1021px) {
      top: calc(50% - -100px);
    }
    @media screen and (min-height: 1250px) {
      top: calc(50% - 17.5px);
    }
    .hall-bear {
      @include media("<=889px") {
        display: none;
      }
      &.locked:hover {
        cursor: $cursor-Default;
      }
      &:hover {
        cursor: $cursor-Pointer;
      }
    }

    .barberian-king-video {
      width: 375px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);
      @media screen and (min-height: 1080px) {
        width: 450px;
      }
    }

    // King Bear

    .king-bear-wrapper {
      position: absolute;
      top: calc(50% - 10.5rem);
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - 20rem);
      }
      &:hover .mint-auction {
        opacity: 1;
        transform: translateY(-30px);
      }
      .mint-auction {
        display: flex;

        pointer-events: none;
        font-family: $font-superStar;
        position: absolute;
        top: 6rem;
        left: 14rem;
        text-align: center;
        opacity: 0;
        font-size: 28px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 20rem;
        z-index: 100;
        .hall-frame {
          position: absolute;
          width: 175px;
          top: -3.5rem;
          left: -3.7rem;
          z-index: 10;
        }
        .mint-auction-text {
          position: absolute;
          left: -1.6rem;
          color: black;
          z-index: 10;
        }
      }
      .king-bear-video {
        width: 500px;
        height: 500px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
        video {
          width: 500px;
          height: 500px;
        }
        .king-hover {
          position: absolute;
          left: 0;
          opacity: 0;
        }

        &:hover {
          video {
            opacity: 0;
          }
          .king-hover {
            opacity: 1;
          }
        }
      }
    }
    // Demon Bear
    .demon-bear-wrapper {
      position: absolute;
      top: calc(50% - 10rem);
      left: 27.5%;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - 21rem);
        left: 30%;
      }
      &:hover .holders-dash {
        opacity: 1;
        transform: translateY(-25px);
      }
      .holders-dash {
        pointer-events: none;
        font-family: $font-superStar;
        position: absolute;
        top: -2rem;
        left: 4.5rem;
        width: 20rem;
        opacity: 0;
        font-size: 28px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        .hall-frame {
          position: absolute;
          width: 210px;
          top: -4.4rem;
          left: -1.5rem;
          z-index: 9;
        }

        .holders-dash-text {
          position: absolute;
          color: black;
          z-index: 11;
        }
      }
      .demon-bear-video {
        width: 300px;
        height: 175px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 300px;
          height: 175px;
        }
        .demon-hover {
          position: absolute;
          left: 0;
          opacity: 0;
        }

        &:hover {
          video {
            opacity: 0;
          }
          .demon-hover {
            opacity: 1;
          }
        }
      }
    }

    // Lich Bear
    .lich-bear-wrapper {
      position: absolute;
      top: calc(50% - -3rem);
      left: 30%;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - 8rem);
      }
      &:hover .loss-calculator {
        opacity: 1;
        transform: translateY(-15px);
      }
      &:hover .lich-glow {
        clip-path: inset(0 0 0 0);
        opacity: 0.4;
      }
      .lich-glow {
        position: absolute;
        background: rgb(255, 175, 13);
        background: linear-gradient(
          0deg,
          rgba(255, 175, 13, 1) 0%,
          rgba(254, 245, 56, 0) 100%
        );
        width: 250px;
        height: 300px;
        top: 5rem;
        z-index: 2;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: inset(100% 0 0 0);
        opacity: 0;
        transform-style: preserve-3d;
        transform: rotateX(45deg);
        perspective: 1000px;
        border-radius: 50px;
        filter: blur(1px);
        pointer-events: none;
      }
      .loss-calculator {
        pointer-events: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-superStar;
        position: absolute;
        top: -1rem;
        left: -0.6rem;
        opacity: 0;
        font-size: 22px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 20rem;
        .hall-frame {
          position: absolute;
          width: 210px;
          top: -5.75rem;
          left: 4rem;
          z-index: 9;
        }

        .loss-calculator-text {
          position: absolute;
          color: black;
          z-index: 11;
          left: 53%;
          transform: translateX(-50%);
          font-size: 32px;
        }
      }
      .lich-bear-video {
        width: 275px;
        height: 328px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 275px;
          height: 328px;
        }
        .lich-hover {
          opacity: 0;
          position: absolute;
          left: 0;
        }

        &:hover {
          video {
            opacity: 0;
          }
          .lich-hover {
            opacity: 1;
          }
        }
      }
    }

    // Anubis bear
    .anubis-bear-wrapper {
      position: absolute;
      top: calc(50% - -12rem);
      left: 10%;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-height: 1080px) {
        top: calc(50% - 1rem);
      }
      // @include media("<=1700px") {
      //   top: calc(50% - -9rem);
      // }
      // @media screen and (min-height: 1150px) {
      //   top: calc(50% - -22rem);
      // }
      &:hover .wl-checker {
        opacity: 1;
        transform: translateY(-15px);
      }
      &:hover .anubis-glow {
        clip-path: inset(0 0 0 0);
        opacity: 0.4;
      }
      .anubis-glow {
        position: absolute;
        background: rgb(255, 175, 13);
        background: linear-gradient(
          0deg,
          rgba(255, 175, 13, 1) 0%,
          rgba(254, 245, 56, 0) 100%
        );
        width: 200px;
        height: 300px;
        top: 5.5rem;
        left: -0.5rem;
        z-index: 2;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: inset(100% 0 0 0);
        opacity: 0;
        transform-style: preserve-3d;
        transform: rotateX(45deg);
        perspective: 1000px;
        border-radius: 45px;
        filter: blur(1px);
        pointer-events: none;
        &::before {
          content: "";
          position: absolute;
          top: 75px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background-color: rgba(35, 35, 35, 0.3);
          transform-origin: center center -100px;
          transform: rotateX(-90deg);
        }
      }
      .wl-checker {
        pointer-events: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-superStar;
        position: absolute;
        top: -1rem;
        left: -3.65rem;
        opacity: 0;
        font-size: 28px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 20rem;
        .hall-frame {
          position: absolute;
          width: 210px;
          top: -5.75rem;
          left: 3.4rem;
          z-index: 9;
        }

        .wl-checker-text {
          position: absolute;
          color: black;
          z-index: 11;
        }
      }
      .anubis-bear-video {
        width: 240px;
        height: 327px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 240px;
          height: 327px;
        }
        .anubis-hover {
          position: absolute;
          left: 0;
          opacity: 0;
        }
        &:hover {
          video {
            opacity: 0;
          }
          .anubis-hover {
            opacity: 1;
          }
        }
      }
    }

    .sage-bear-wrapper {
      width: 5rem;
      position: absolute;
      top: calc(50% - -20rem);
      left: 15%;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - -5rem);
      }
      @media screen and (min-height: 1117px) {
        top: calc(50% - -18rem);
      }
      &:hover .tos {
        opacity: 1;
        transform: translateY(-15px);
      }
      &:hover .sage-glow {
        clip-path: inset(0 0 0 0);
        opacity: 0.4;
      }
      .sage-glow {
        position: absolute;
        background: rgb(255, 175, 13);
        background: linear-gradient(
          0deg,
          rgba(255, 175, 13, 1) 0%,
          rgba(254, 245, 56, 0) 100%
        );
        width: 200px;
        height: 300px;
        top: 4rem;
        left: -3rem;
        z-index: 2;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: inset(100% 0 0 0);
        opacity: 0;
        transform-style: preserve-3d;
        transform: rotateX(45deg);
        perspective: 1000px;
        border-radius: 45px;
        filter: blur(1px);
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          top: 75px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background-color: rgba(35, 35, 35, 0.3);
          transform-origin: center center -100px;
          transform: rotateX(-90deg);
        }
      }
      .tos {
        pointer-events: none;
        width: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-superStar;
        position: absolute;
        top: -1rem;
        opacity: 0;
        font-size: 24px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        .hall-frame {
          position: absolute;
          width: 225px;
          top: -5.75rem;
          left: 3rem;
          z-index: 9;
        }
        .tos-text {
          position: absolute;
          color: black;
          z-index: 11;
          top: -0.75rem;
        }
      }
      .sage-bear-video {
        width: 228px;
        height: 304px;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 228px;
          height: 304px;
        }
        .sage-hover {
          position: absolute;
          left: -4.65rem;
          opacity: 0;
        }
        &:hover {
          video {
            opacity: 0;
          }
          .sage-hover {
            opacity: 1;
          }
        }
      }
    }

    .cowboy-bear-wrapper {
      width: 5rem;
      position: absolute;
      top: calc(50% - -5rem);
      right: 10%;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - 6rem);
      }
      &:hover .eth-trader {
        opacity: 1;
        transform: translateY(-15px);
      }
      &:hover .cowboy-glow {
        clip-path: inset(0 0 0 0);
        opacity: 0.5;
      }
      .cowboy-glow {
        position: absolute;
        background: rgb(255, 175, 13);
        background: linear-gradient(
          0deg,
          rgba(255, 175, 13, 1) 0%,
          rgba(254, 245, 56, 0) 100%
        );
        top: -0.75rem;
        width: 200px;
        height: 300px;
        top: 2.75rem;
        left: -2.5rem;
        z-index: 2;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: inset(100% 0 0 0);
        opacity: 0;
        transform-style: preserve-3d;
        transform: rotateX(45deg);
        perspective: 1000px;
        border-radius: 50px;
        filter: blur(1px);
        pointer-events: none;
      }

      .eth-trader {
        left: -1.5rem;
        width: 10rem;

        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-superStar;
        position: absolute;
        top: -1rem;
        left: -5.5rem;
        opacity: 0;
        font-size: 28px;
        width: 20rem;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        .hall-frame {
          position: absolute;
          width: 200px;
          top: -5.75rem;
          left: 3.5rem;
          z-index: 9;
        }
        .eth-trader-text {
          position: absolute;
          color: black;
          z-index: 11;
          top: -1.6rem;
          left: 48.5%;
          transform: translateX(-50%);
        }
      }
      .cowboy-bear-video {
        z-index: 1;
        width: 248px;
        height: 284px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 248px;
          height: 284px;
        }
        .cowboy-hover {
          position: absolute;
          left: -5.25rem;
          opacity: 0;
        }
        &:hover {
          video {
            opacity: 0;
          }
          .cowboy-hover {
            opacity: 1;
          }
        }
      }
    }

    .bull-bear-wrapper {
      width: 5rem;
      position: absolute;
      top: calc(50% - -13rem);
      right: -10%;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - 1rem);
      }
      &:hover .hall-whitepaper-text {
        opacity: 1;
        transform: translateY(-15px);
      }
      &:hover .bull-glow {
        clip-path: inset(0 0 0 0);
        opacity: 0.4;
      }
      .bull-glow {
        position: absolute;
        background: rgb(255, 175, 13);
        background: linear-gradient(
          0deg,
          rgba(255, 175, 13, 1) 0%,
          rgba(254, 245, 56, 0) 100%
        );
        width: 225px;
        height: 300px;
        top: 3.5rem;
        left: -4rem;
        z-index: 2;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: inset(100% 0 0 0);
        opacity: 0;
        transform-style: preserve-3d;
        transform: rotateX(45deg);
        perspective: 1000px;
        border-radius: 45px;
        filter: blur(1px);
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          top: 75px;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background-color: rgba(35, 35, 35, 0.3);
          transform-origin: center center -100px;
          transform: rotateX(-90deg);
        }
      }
      .hall-whitepaper-text {
        left: -1.5rem;
        width: 10rem;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-superStar;
        position: absolute;
        top: -1rem;
        opacity: 0;
        font-size: 28px;
        width: 20rem;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        .hall-frame {
          position: absolute;
          width: 200px;
          top: -5.75rem;
          left: -1.75rem;
          z-index: 9;
        }
        .hall-whitepaper-text {
          position: absolute;
          color: black;
          z-index: 11;
          top: -0.6rem;
          left: -5.4rem;
        }
      }
      .bull-bear-video {
        width: 244px;
        height: 294px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 244px;
          height: 294px;
        }
        .bull-hover {
          position: absolute;
          left: -5.15rem;
          opacity: 0;
        }
        &:hover {
          video {
            opacity: 0;
          }
          .bull-hover {
            opacity: 1;
          }
        }
      }
    }

    .moneybag-bear-wrapper {
      width: 5rem;
      position: absolute;
      top: calc(50% - -19rem);
      right: 7%;
      z-index: 1;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-height: 1080px) {
        top: calc(50% - -3rem);
      }
      @media screen and (min-height: 1117px) {
        top: calc(50% - -17rem);
      }
      &:hover .hall-marketplace-text {
        opacity: 1;
        transform: translateY(-15px);
      }
      &:hover .marketplace-glow {
        clip-path: inset(0 0 0 0);
        opacity: 0.4;
      }
      .marketplace-glow {
        position: absolute;
        background: rgb(255, 175, 13);
        background: linear-gradient(
          0deg,
          rgba(255, 175, 13, 1) 0%,
          rgba(254, 245, 56, 0) 100%
        );
        width: 200px;
        height: 300px;
        top: 5rem;
        z-index: 2;
        transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        clip-path: inset(100% 0 0 0);
        opacity: 0;
        transform-style: preserve-3d;
        transform: rotateX(45deg);
        perspective: 1000px;
        border-radius: 50px;
        filter: blur(1px);
        pointer-events: none;
      }
      .hall-marketplace-text {
        left: -1.5rem;

        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-superStar;
        position: absolute;
        top: 0rem;
        left: -7.25rem;
        opacity: 0;
        font-size: 28px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 20rem;
        .hall-frame {
          position: absolute;
          width: 210px;
          top: -5.75rem;
          left: 3rem;
          z-index: 9;
        }
        .hall-marketplace-text {
          position: absolute;
          color: black;
          z-index: 11;
          top: -0.4rem;
          left: -0.5rem;
        }
      }
      .moneybag-bear-video {
        width: 185px;
        height: 319px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        video {
          width: 185px;
          height: 319px;
        }
        .moneybag-hover {
          position: absolute;
          left: -3.3rem;
          opacity: 0;
        }
        &:hover {
          video {
            opacity: 0;
          }
          .moneybag-hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
