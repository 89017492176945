@import "../../styles/variables";

.home {
  width: 100vw;
  overflow-x: hidden;
  .landing-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .homelanding-pic {
      position: absolute;
      width: 100vw;
      height: 100vh;
      background-image: url("../../assets/media/Intro/Hall/hallBackground.webp");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      background-color: #1c1414;
      @include media("<=480px") {
        display: none;
      }
    }
    .bear-king-wrapper {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -4.15vh;
      z-index: 2;

      @media screen and (max-height: 850px) {
        top: 0;
      }
      video {
        cursor: $cursor-Pointer;
        position: relative;
        top: calc(32.5vh - 305px);
        width: 500px;

        @include media("<=480px") {
          top: calc(50% - 25.5rem);
        }
        @include media("<=380px") {
          width: 250px;
          top: calc(50% - 22.5rem);
        }
      }
    }

    .barbearians-title-wrapper {
      position: absolute;
      top: 4rem;
      width: 100%;
      z-index: 1;
      text-shadow: 0px 15.2108px 0px #000000;
      @include media("<=889px") {
        top: 9rem;
      }
      @include media("<=480px") {
        top: 6.5rem;
      }
      h1 {
        font-family: $font-superStar;
        color: white;
        font-size: 192px;
        text-align: center;
        font-weight: 400;
        opacity: 0;
        scale: 0.8;
        @include media("<=889px") {
          font-size: 144px;
        }
        @include media("<=480px") {
          font-size: 56px;
        }
      }
    }
    .subtitle-wrapper {
      position: absolute;
      bottom: 10rem;
      width: 100%;
      z-index: 2;
      opacity: 0;
      @include media("<=480px") {
        bottom: 9rem;
        padding: 0.5rem 2rem;
        background: rgba(0, 0, 0, 0.7);
        width: 85%;
        line-height: normal;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
      }
      h3 {
        font-family: $font-superStar;
        color: #a69f9f;
        font-size: 32px;
        text-align: center;
        font-weight: 400;

        @include media("<=480px") {
          font-size: 18px;
        }
      }
    }
    .cta-btn-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 6rem;
      z-index: 15;

      opacity: 0;
      gap: 1rem;
      @include media("<=950px") {
        flex-direction: column;
        bottom: 1rem;
        gap: 0.25rem;
      }
      @include media("<=480px") {
        bottom: 2rem;
      }
      .home-email-input-wrapper {
        .email-input {
          width: 20rem;
          background-color: #222222;
          border: none;
          outline: none;
          color: white;
          padding: 0.5rem 0.5rem;
          font-size: 20px;
          border: 2px solid #3e3e3e;
          border-radius: 7.5px;
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          &:focus {
            background-color: #333333;
          }
        }
      }
      .home-cta-btn {
        background-color: #222222;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.3rem 1.5rem;
        font-size: 24px;
        color: #7f1d1d;
        cursor: $cursor-Pointer;
        border: 2px solid #3e3e3e;
        border-radius: 7.5px;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        font-family: $font-superStar;
        &:hover {
          background-color: #343434;
        }
      }
    }
  }
  .description-section {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: hsla(0, 76%, 6%, 1);
    background-image: radial-gradient(
        at 60% 96%,
        hsla(17, 0%, 0%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 16% 26%, hsla(337, 0%, 0%, 1) 0px, transparent 50%),
      radial-gradient(at 99% 2%, hsla(339, 0%, 0%, 1) 0px, transparent 50%);
    @include media("<=889px") {
      height: auto;
    }
    @include media("<=480px") {
      height: 10vh;
    }
    .description-title {
      width: 100%;
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include media("<=889px") {
        height: 50vh;
      }
      @include media("<=480px") {
        height: 25vh;
      }
      p {
        text-align: center;
        color: #fff;
        font-size: 48px;
        font-weight: 400;
        line-height: 68px;
        max-width: 89.2rem;
        @include media("<=889px") {
          text-align: left;
          padding: 0 2rem;
          width: 100vw;
          line-height: normal;
        }
        @include media("<=480px") {
          font-size: 24px;

          text-align: left;
          max-width: none;
        }

        .orange {
          font-weight: 400;
          color: #7f1d1d;
        }
      }
    }
    .bear-cards-wrapper {
      display: flex;
      justify-content: center;
      @include media("<=889px") {
        display: none;
      }
      .card-2,
      .card-3,
      .card-4,
      .card-5 {
        position: absolute;
      }
      .bear-cards {
        display: flex;
        width: 82.5rem;
        // justify-content: space-evenly;
        img {
          box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
            6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
            12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
            22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
            41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
            100px 100px 80px rgba(0, 0, 0, 0.07);
        }
      }
    }
  }

  .bear-count-section {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    background-color: hsla(0, 76%, 6%, 1);
    background-image: radial-gradient(
        at 58% 0%,
        hsla(240, 0%, 0%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 55% 58%, hsla(340, 0%, 0%, 1) 0px, transparent 50%),
      radial-gradient(at 94% 93%, hsla(340, 0%, 2%, 1) 0px, transparent 50%);
    .bear-count-wrapper {
      width: 100%;
      height: 33.33%;
      display: flex;
      justify-content: center;
      position: relative;
      @include media("<=889px") {
        height: 15rem;
      }
      @include media("<=480px") {
        height: auto;
      }
      .inner-title {
        display: flex;
        .bear-count {
          font-size: 38px;
          font-weight: 100;
          position: relative;
          top: 6rem;
          opacity: 0;
          @include media("<=889px") {
            display: none;
          }
        }
        .ten-thousand {
          font-family: $font-superStar;
          font-size: 256px;
          opacity: 0;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          &.ten-thousand-2 {
            opacity: 1;
          }

          @include media("<=480px") {
            font-size: 128px;
            height: 20rem;
          }
        }
      }
    }
    .bear-count-subtitle {
      height: 33.33%;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 32px;
      letter-spacing: 1.2px;
      font-weight: 400;
      .only-tenthousand {
        text-align: right;
        width: 38rem;
        position: relative;
        @include media("<=480px") {
          text-align: left;
          width: 100vw;
          padding: 0 2rem;
          line-height: normal;
        }
      }
      .will-be-pulled {
        text-align: left;
        width: 35%;
        position: relative;
        line-height: normal;
        @include media("<=950px") {
          width: 38rem;
        }
        @include media("<=480px") {
          width: 100vw;
          padding: 0 2rem;
        }
      }
    }

    .bear-count-paragraph-wrapper {
      position: relative;
      height: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media("<=889px") {
        margin-bottom: 10rem;
      }
      @include media("<=480px") {
        display: none;
      }
      .bear-animation-wrapper {
        width: 100%;
        max-width: 109rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include media("<=950px") {
          height: 10rem;
        }
        .line-svg {
          position: absolute;
          display: none;
        }
        .tackling-bull {
          position: absolute;
          top: -10.7rem;
          left: 60%;
          transform: translateX(-50%);
          transform-origin: bottom left;
          z-index: 4;
          display: none;
          @include media("<=480px") {
            display: none;
          }
          img {
            width: 200px;
          }
        }
      }
      .paragraph-wrapper {
        display: flex;
        justify-content: center;
        gap: 2.5rem;
        margin-top: 1rem;
        padding: 4rem 0;
        background-color: transparent;
        line-height: 175%;
        z-index: 10;
        @include media("<=950px") {
          padding: 2rem 3rem;
          width: 100%;
          line-height: 225%;
        }
        p {
          max-width: 52.5%;
          font-family: $font-Pixellari;
          font-size: 24px;
          @include media("<=950px") {
            font-size: 32px;
            max-width: none;
            width: 100%;
          }
        }
      }
    }
  }
}

.placeholder-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  font-size: 24px;
  position: relative;
  font-family: $font-superStar;
  border: 2px solid #3e3e3e;
  border-radius: 7.5px;
  padding: 0.5rem 1rem;
  background-color: #202020;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    background-color: #303030;
    cursor: $cursor-Pointer;
  }
  &.not-available {
    color: gray;
    @include media("<=950px") {
      transform: translateY(30px);
    }
  }
  &.not-available:hover {
    cursor: $cursor-Unavailable;
  }

  span {
    color: #f27921;
    font-family: $font-superStar;
    font-size: 22px;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
