@import "../../styles/variables";

.hall-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  position: fixed;
  top: 7rem;
  left: 2.5rem;
  z-index: 15;
  clip-path: $pixelBorderRadius;
  cursor: $cursor-Pointer;
  font-family: $font-superStar;
  font-size: 24px;
  color: white;
  background: rgba(36, 36, 36, 0.55);
  box-shadow: 0 8px 32px 0 rgba(75, 75, 75, 0.37);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    color: #7f1d1d;
  }
  @include media("<=480px") {
    left: 0.5rem;
  }
}
