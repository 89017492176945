@import "../../styles/variables.scss";

$cursor-PointerQuests: url("../../assets/media/Cursors/cursorPointer.png"),
  pointer;

.whitelist-quests {
  width: 100vw;
  background-image: url("../../assets/media/images/missionsBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 2.5rem;
  padding: 3.5rem;
  background-color: hsla(7, 26%, 10%, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include media("<=889px") {
    padding: 1rem;
    background-image: none;
  }
  .whitelist-quests-texts-wrapper {
    width: 100vw;
    .whitelist-little-text {
      color: #ffb137;
      display: flex;
      justify-content: center;
      font-family: $font-superStar;
      font-size: 28px;
      @include media("<=480px") {
        font-size: 22px;
      }
    }
    .whitelist-big-text {
      color: white;
      display: flex;
      justify-content: center;
      font-family: $font-superStar;
      font-size: 74px;
      text-shadow: 0px 7.5px 0px #000000;

      @include media("<=889px") {
        line-height: normal;
        text-align: center;
        font-size: 42px;
        margin-bottom: 2.5rem;
        text-shadow: 0px 5px 0px #000000;
      }
    }
  }
  .whitelist-quests-cards-wrapper {
    gap: 2rem;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    @include media("<=889px") {
      flex-direction: column;
      gap: 3.25rem;
    }
    .whitelist-quests-card {
      overflow: hidden;
      position: relative;
      background-color: #1c1e24;
      width: 25rem;
      height: 35rem;
      border-radius: 32px;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      z-index: 2;
      transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
          rgb(51, 51, 51) 0px 0px 0px 4px;
      }
      @include media("<=480px") {
        border-radius: 0;
      }
      .leftcard-gradient {
        position: absolute;
        top: 6.5rem;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background: rgb(28, 30, 36);
        background: linear-gradient(
          180deg,
          rgba(28, 30, 36, 1) 10%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .middlecard-gradient {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 15rem;
        background: rgb(28, 30, 36);
        background: linear-gradient(
          180deg,
          rgba(28, 30, 36, 1) 30%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .rightcard-gradient {
        position: absolute;
        top: 5rem;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background: rgb(28, 30, 36);
        background: linear-gradient(
          180deg,
          rgba(28, 30, 36, 1) 30%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .leftcard-background {
        position: absolute;
        width: 100%;
        bottom: 3.5rem;
      }
      .rightcard-background {
        position: absolute;
        bottom: 1rem;
        width: 100%;
        pointer-events: none;
      }
      &.card-middle {
        width: 30rem;
        height: 40rem;
        -webkit-box-shadow: 0px 0px 54px 8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 54px 8px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 54px 8px rgba(0, 0, 0, 0.75);
        z-index: 3;
        transition: 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
            rgb(51, 51, 51) 0px 0px 0px 4px;
        }
        .middlecard-background {
          width: 100%;
          top: 7rem;
          position: absolute;
          pointer-events: none;
        }
      }
      .whitelist-quests-card-texts-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #dedede;
        z-index: 1;
        .whitelist-quests-card-title {
          font-family: $font-superStar;
          font-size: 42px;
          text-shadow: 0px 5px 0px #000000;
        }
        .whitelist-quests-card-description {
          font-size: 24px;
          text-align: center;
          line-height: normal;
          color: #999999;
          &.right {
            font-size: 20px;
          }
          @include media("<=480px") {
            padding: 0 2rem;
            font-size: 22px;
          }
        }
      }
      .whitelist-quests-card-button-wrapper {
        width: 100%;
        height: 4rem;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;

        .card-bottom-background {
          position: absolute;
          scale: 2;
          bottom: 0.7rem;
          left: -0.75rem;
          filter: contrast(1.5) brightness(0.3);
          pointer-events: none;
          &.middle {
            scale: 1.6;
            bottom: 1.1rem;
            left: -1rem;
          }
        }
        .whitelist-quests-card-button {
          position: absolute;
          top: -1rem;
          cursor: $cursor-Pointer;
          display: flex;
          align-items: center;
          padding: 0.5rem 1rem;
          background-color: #7f1d1d;
          box-shadow: 0px 3.49091px 0px #4f0e0e,
            0px 6.54545px 0px rgba(0, 0, 0, 0.15);
          border-radius: 15.7091px;
          z-index: 5;
          font-family: $font-superStar;
          color: #fff;
          border-radius: 7.5px;
          font-size: 24px;
          gap: 0.5rem;
          border-radius: 7.5px;
          transition: 0.1s ease-in;
          &:hover {
            transform: translateY(2.5px);
            box-shadow: 0px 0px 0px #4f0e0e, 0px 0px 0px rgba(0, 0, 0, 0.15);
          }
          &:active {
            transform: translateY(5px);
          }
          .twitter-share-button {
            cursor: $cursor-PointerQuests !important;
            z-index: 10;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}
