@import "../../styles/variables";

.mobile-side-menu {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  color: white;
  background: rgba(58, 58, 58, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: 0.5s cubic-bezier(0.25, 0.13, 0.31, 0.85);
  &.active {
    transform: translateX(0);
    display: flex;
    transition: 0.5s cubic-bezier(0.25, 0.13, 0.31, 0.85);
  }
  @include media("<=480px") {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  a {
    font-size: 36px;
  }
  .close-btn-wrapper {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 50px;
    align-items: center;
    padding: 2rem;
    .close-btn {
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      clip-path: $pixelBorderRadius;
      background-color: #8a8a8a;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.navbar {
  z-index: 20;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  background-color: #0c0f12;
  padding: 1rem 3rem 1rem 2rem;
  border-bottom: 2px solid #3e3e3e;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  @include media("<=950px") {
    padding: 0.75rem 1rem;
  }
  @include media("<=480px") {
    padding: 0.75rem 0.25rem;
  }
  &.hide {
    transform: translateY(-100%);
  }
  .nav-left-side {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    @include media("<=950px") {
      gap: 0.75rem;
    }

    img {
      cursor: $cursor-Pointer;
      width: 85px;
      @include media("<=950px") {
        width: 80px;
      }
      @include media("<=480px") {
        position: relative;
        left: 0.5rem;
      }
    }

    a {
      height: 3rem;
      display: flex;
      align-items: center;
      cursor: $cursor-Pointer;
      text-decoration: none;
      color: #dedede;
      font-family: $font-superStar;
      font-size: 24px;
      transition: color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media("<=480px") {
        display: none;
      }
      &:hover {
        color: #fff;
      }
      &.active {
        color: #7f1d1d;
        border-bottom: 3px solid #7f1d1d;
      }
    }
  }

  .nav-right-side {
    display: flex;
    margin-right: 1rem;
    gap: 0.75rem;
    @include media("<=480px") {
      gap: 0.5rem;
    }
    .volume-button-wrapper {
      display: flex;
      align-items: center;
      padding: 0.65rem 1rem;
      cursor: $cursor-Pointer;
      border: 2px solid #3e3e3e;
      border-radius: 7.5px;
      svg {
        path {
          fill: #7f1d1d;
        }
      }
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .mobile-side {
      display: none;
      width: 50px;
      height: 50px;
      background-color: #222222;
      clip-path: $pixelBorderRadius;
      transform: translateX(25px);
      z-index: 2;
      @include media("<=480px") {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(0);
      }
      svg {
        width: 32.5px;
        height: 32.5px;
      }
    }
    .bearcoin-container {
      display: flex;
      align-items: center;
      width: 7.5rem;
      border: 2px solid #3e3e3e;
      border-radius: 7.5px;
      .bear-coin-wrapper {
        padding: 0.25rem;
        border-right: 2px solid #3e3e3e;
        width: 35%;
      }
      .bearcoin-value-wrapper {
        display: flex;
        width: 65%;
        height: 100%;
        align-items: center;
        justify-content: center;
        .bear-coin {
          color: #ffffff80;
          font-family: $font-superStar;
          text-align: center;
          font-size: 22px;
        }
      }
    }
    .wallet-container {
      display: flex;
      align-items: center;
      width: 9rem;
      border: 2px solid #3e3e3e;
      border-radius: 7.5px;
      .wallet-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        border-right: 2px solid #3e3e3e;
        // padding: 0.25rem;
        height: 100%;
      }
      .bearcoin-value-wrapper {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0.25rem;
        .wallet-value {
          color: #ffffff80;
          font-family: $font-superStar;
          text-align: center;
          font-size: 22px;
        }
        img {
          width: 15px;
        }
      }
    }
  }
}
